import { Trans } from 'react-i18next';
import './Testimony.scss';

function Testimony(props: { testimonyI18nKey: string, studentI18nKey: string }) {

  return (
    <>
      <p><Trans i18nKey={props.testimonyI18nKey} /></p>
      <div><Trans i18nKey={props.studentI18nKey} /></div>
    </>
  );
}

export default Testimony;
